















































































































	import {Component, Vue} from 'vue-property-decorator';
	import SectionTitle from "@/components/SectionTitle.vue";
	import GmSection from "@/components/GmSection.vue";
	import {HashLink} from "@/router/navigation";
	import MainAction from "@/components/buttons/MainAction.vue";


	@Component({components: {MainAction, SectionTitle, GmSection}})
	export default class extends Vue {
		get sectionId() {return HashLink.conLocation}
	}
