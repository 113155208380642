








	import {Component, Vue} from 'vue-property-decorator';
	import Map from "@/views/contacts/Map.vue";
	import Location from "@/views/contacts/Location.vue";
	import Logistics from "@/views/contacts/Logistics.vue";

	@Component({components: {Logistics, Map, Location}})
	export default class extends Vue {
	}
