






	import {Component, Vue} from 'vue-property-decorator';
	import SectionTitle from "@/components/SectionTitle.vue";
	import GmSection from "@/components/GmSection.vue";
	import {HashLink} from "@/router/navigation";
	import * as L from 'leaflet';

	@Component({components: {SectionTitle, GmSection}})
	export default class extends Vue {
		get sectionId() {return HashLink.conMap}

		map: L.Map = null;
		layer: L.Layer = null;

		mounted() {
			this.$nextTick(() => {
				const el = this.$refs.map;
				this.map = L.map(el, {
				  preferCanvas:true,
          scrollWheelZoom: false,
				}).setView([59.442408164281524, 24.77098469905154], 15);

				// L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: ''}).addTo(this.map);
        // L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {attribution: ''}).addTo(this.map);
        // L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {attribution: ''}).addTo(this.map);
				this.mapBoxMy();

				L.marker([59.442424528032795, 24.771038343229282])
					.addTo(this.map)
					.bindPopup('Uus-Sadama 24/4, 10120, Tallinn')
 					.openPopup()
					;
			})
		}
		mapBoxMy() {
			const tok = 'pk.eyJ1IjoieG9rb3JpbDM2MiIsImEiOiJjazZpbnJtaDUwMzB0M21udWpzZjZlY2R2In0.THdVLcJwXyssurvB6VXD0A';
			this.layer = L.tileLayer(`https://api.mapbox.com/styles/v1/xokoril362/ck6ipz3qm0l951iojfbb2a202/tiles/256/{z}/{x}/{y}?access_token=${tok}`, {
				attribution: '...',
			}).addTo(this.map);
		}
	}
